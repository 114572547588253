import React, { Fragment, Component } from "react";
import { Link, graphql } from "gatsby";
import { colors, breakpoints, fonts } from "../style-utilities/variables";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";

import ContactBanner from "../components/ContactBanner";
import NavBar from "../components/NavBar";
import SEO from "../components/SEO";

const StyledLocationPage = styled.main`
  margin: 0.75em auto;
  margin-top: 0;
  max-width: 1500px;
  overflow-x: hidden;
  @media (min-width: 1500px) {
    overflow-x: visible;
  }
  h1 {
    margin-bottom: 2em;
  }
  .left-container {
    padding-right: 1em;
  }
  .circle-section {
    position: relative;
    padding-left: 1em;
    /* overflow: hidden; */

    .right-container {
      position: relative;
      .circle-container {
        width: 450px;
        padding-top: 450px;
        border-radius: 100%;
        position: absolute;
        right: calc(50% - 217px);
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        object-fit: cover;
        @media (min-width: 400px) {
          left: auto;
          margin: 2em auto;
        }
      }
      .circle-copy {
        padding-top: 540px;
        padding-right: 1em;
        strong {
          font-size: 26px;
          color: black;
        }
        p {
          font-size: 22px;
        }
      }
    }

    @media (min-width: ${breakpoints.tablet}) {
      display: flex;
      justify-content: space-around;
      padding: 0 0 0 2.5em;
      .left-container {
        width: 22%;
        max-width: 210px;
        margin-left: auto;
      }
      .right-container {
        width: 70%;
        padding-top: 0;
        max-width: 860px;
        .circle-container {
          width: 100%;
          padding-top: 100%;
          right: -30px;
        }
        .circle-copy {
          padding: 112% 2em 4em 0;
        }
      }
    }

    @media (min-width: ${breakpoints.desktop}) {
      padding: 0 0 0 4.5em;
      .left-container {
        width: 28%;
        margin-right: -30px;
      }
      .right-container {
        width: 72%;
      }
      .circle-copy {
        max-width: 85%;
      }
    }
  }

  h1 {
    font-size: 48px;
  }

  .info {
    border-bottom: 2px solid ${colors.lightGray};
    margin-bottom: 1.5em;
    padding-bottom: 1em;

    h2 {
      font-size: 22px;
      margin-bottom: 0.2em;
    }
    p {
      font-size: 16px;
      margin-bottom: 0.5em;
    }
    a {
      font-size: 16px;
      color: ${colors.primary};
    }
  }
  .info:last-child() {
    border-bottom: none;
  }
`;

class LocationPageTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewPayload: null,
      isPreviewMode: false
    };
  }

  componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    /*
    Preview Mode -------------------------------------
    */
    if (params.get("preview")) {
      let id = params.get("id");
      let wpnonce = params.get("_wpnonce");
      let BASE_URL = `https://api.dev.ansira.io/wp-json/wp/v2`;

      fetch(`${BASE_URL}/locations/${id}/revisions/?_wpnonce=${wpnonce}`, {
        mode: "cors",
        credentials: "include"
      })
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson[0]
            });
          }
        });
    }
    /*
    -------------------------------------------------
    */
  }

  render() {
    let post = this.props.data.wordpressWpLocations;

    if (this.state.isPreviewMode) {
      post.acf = post.title = this.state.previewPayload.acf;
      post.title = this.state.previewPayload.title.rendered;
      post.content = this.state.previewPayload.content.rendered;
    }
    return (
      <StyledLocationPage className="animate">
        <SEO
          isBlogPost={false}
          postData={post}
          postImage={post.better_featured_image.source_url}
        />
        <NavBar link="/contact" name="Contact Us" />
        <h1 className="page-container">{post.title}</h1>
        <div className="circle-section">
          <div className="left-container">
            {/* {post.acf.phone ? (
              <div className="phone info">
                <h2>Phone</h2>
                <p>{post.acf.phone}</p>
              </div>
            ) : (
              <></>
            )} */}

            <div className="address info">
              <h2>Address</h2>
              {ReactHtmlParser(post.acf.address)}
              <p>
                <a
                  href={post.acf.get_directions_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get Directions
                </a>
              </p>
            </div>
            <div className="info hours">
              <h2>Office Hours</h2>
              {ReactHtmlParser(post.acf.hours)}
            </div>
          </div>
          <div className="right-container">
            <div
              className="circle-container"
              style={{
                backgroundImage:
                  "url(" + post.better_featured_image.source_url + ")"
              }}
            ></div>
            <div className="circle-copy">{ReactHtmlParser(post.content)}</div>
          </div>
        </div>
        <ContactBanner
          headline={post.acf.contact_headline}
          cta={post.acf.contact_cta}
          url={post.acf.contact_url}
        />
      </StyledLocationPage>
    );
  }
}

export const query = graphql`
  query($slug: String!) {
    wordpressWpLocations(slug: { eq: $slug }) {
      slug
      title
      content
      acf {
        seo_title
        seo_canonical
        seo_description
        address
        hours
        get_directions_url
        contact_headline
        contact_cta
        contact_url
      }
      better_featured_image {
        source_url
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
  }
`;

export default LocationPageTemplate;
